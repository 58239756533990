import React from 'react';

import ReferenciaItem from './referenciaItem';

export default function Referencias() {
  return (
    <div>
      <h4 className="text-3xl m-0">Referencias</h4>
      <div className="md:flex">
        {/* Grupos */}
        <div className="py-3 md:mr-10">
          <h5 className="text-xl mb-2">
            Grupos{' '}
            <span className="font-normal text-gray-500">| Total: 514 U</span>
          </h5>
          <ReferenciaItem label="1" group>
            186 U 25 x 50
          </ReferenciaItem>
          <ReferenciaItem label="2" group>
            56 U 20 x 50
          </ReferenciaItem>
          <ReferenciaItem label="3" group>
            48 U 20 x 50
          </ReferenciaItem>
          <ReferenciaItem label="4" group>
            60 U 20 x 50
          </ReferenciaItem>
          <ReferenciaItem label="5" group>
            60 U 20 x 50
          </ReferenciaItem>
          <ReferenciaItem label="6" group>
            48 U 20 x 50
          </ReferenciaItem>
          <ReferenciaItem label="7" group>
            56 U 20 x 50
          </ReferenciaItem>
        </div>

        {/* Amenities */}
        <div className="py-3">
          <h5 className="text-xl mb-2">Amenities</h5>
          <div className="lg:flex">
            <div className="lg:mr-6">
              <ReferenciaItem label="A">Estancia Don Juan</ReferenciaItem>
              <ReferenciaItem label="B">
                Caballeriza con picadero exterior
              </ReferenciaItem>
              <ReferenciaItem label="C">Campo de Golf</ReferenciaItem>
              <ReferenciaItem label="D">
                Cancha de deportes ecuestres
              </ReferenciaItem>
              <ReferenciaItem label="E">Cancha de Rugby</ReferenciaItem>
              <ReferenciaItem label="F">Canchas de Tenis</ReferenciaItem>
              <ReferenciaItem label="G">Cancha de Baloncesto</ReferenciaItem>
              <ReferenciaItem label="H">Cancha de Fútbol</ReferenciaItem>
            </div>
            <div>
              <ReferenciaItem label="I">
                Clubhouse con Piscina y Estacionamiento
              </ReferenciaItem>
              <ReferenciaItem label="J">
                Ingreso con garita de control y seguridad
              </ReferenciaItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
