import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Referencias from '../components/masterplan/referencias';
import EtapaCard from '../components/masterplan/etapaCard';
import DesarrolloHero from '../components/sections/desarrolloHero';
import Brochure from '../components/sections/brochure';
import Ubicacion from '../components/sections/ubicacion';
import FullscreenImageParallax from '../components/sections/fullscreenImageParallax';

const LaHaciendaCountryPage = () => (
  <Layout>
    <Seo title="La Hacienda: Country Club" />

    {/* Hero */}
    <DesarrolloHero
      logo={
        <StaticImage
          alt="Logo La Hacienda Country Club"
          src="../images/LH-country-logo_mini-light.jpg"
          className="w-24"
        />
      }
      desarrolloTitle="Country Club"
      heroImg={
        <StaticImage
          alt="Entrada al CLub Country La Hacienda"
          src="../images/la_hacienda-portada.jpg"
          className="rounded-md h-96 lg:w-full lg:h-auto shadow-xl"
        />
      }
      bgColorClass="bg-country-500"
    />

    {/* Brochure */}
    <Brochure
      bgLightClass="bg-country-100"
      bgDarkClass="bg-country-500"
      textColorClass="text-country-500"
      borderColorClass="border-country-500"
    />

    <div className="bg-gray-100">
      {/* Collage */}
      <div>
        <div className="grid gap-2 p-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          <StaticImage
            alt="muelle con reposeras"
            src="../images/lh-country-gallery-1.jpeg"
            className="order-1 md:row-span-1 rounded"
          />
          <StaticImage
            alt="mujer haciendo ejercicio al aire libre"
            src="../images/lh-country-gallery-2.jpg"
            className="order-2 md:row-span-3 md:order-3 xl:order-5 rounded"
          />
          <StaticImage
            alt="hombre jugando al tenis"
            src="../images/lh-country-gallery-3.jpg"
            className="order-3 md:row-span-2 md:order-2 xl:order-6"
            rounded
          />
          <StaticImage
            alt="niñas divirtiendose en la pileta"
            src="../images/lh-country-gallery-4.jpg"
            className="order-4 md:row-span-2 xl:order-2 rounded"
          />
          <div className="flex items-center justify-center p-6 order-5 md:row-span-2 xl:order-3 rounded bg-country-100">
            <p className="m-0 text-center text-2xl text-country-500 font-bold">
              Una urbanización protegida, bajo costo de mantenimiento y rodeada
              de lagunas y árboles, un estilo de vida único!
            </p>
          </div>
          <StaticImage
            alt="casa lujosa en barrio privado"
            src="../images/lh-country-gallery-5.jpg"
            className="order-6 md:order-7 md:row-span-2 rounded"
          />
          <StaticImage
            alt="niña practicando golf"
            src="../images/lh-country-gallery-6.jpg"
            className="order-7 md:row-span-4 md:order-6 xl:order-4 rounded"
          />
        </div>
      </div>

      {/* Ubicacion */}
      <Ubicacion titleColorClass="text-country-500" />
    </div>

    {/* Parallax */}
    <FullscreenImageParallax
      bgImage={
        <StaticImage
          alt="Barrio privado La Hacienda"
          src="../images/lh-country-barrio.jpg"
          className="w-full h-full "
        />
      }
      text="A minutos de la ciudad, con amenities, expensas bajas y hoy con el mejor precio y financiación del mercado inmobiliario de Formosa."
    />

    {/* Etapas */}
    <div className="bg-gray-100 py-20">
      <h1 className="md:text-9xl text-country-500 text-center mb-10">
        Masterplan
      </h1>
      <div className="w-11/12 mx-auto">
        {/* Masterplan + referencias */}
        <div className="xl:sticky top-0 self-start">
          <StaticImage
            alt="Masterplan de La Hacienda"
            src="../images/country-masterplan.png"
            className="mb-6"
          />
          <Referencias />
        </div>

        {/* Etapas */}
        <h4 className="text-3xl m-0 mt-10">Etapas</h4>
        <div className="mt-6 flex flex-wrap -ml-3">
          <EtapaCard
            className="bg-white"
            bgColorClass="bg-country-500"
            date="Ene 21 /  Jun 21"
            etapa="Primera"
            loteo="186">
            <p className="m-0">Acceso</p>
            <p className="m-0">Paisajismo</p>
            <p className="">Golf Course</p>
            <p className="m-0">Infraestructura (1° Parte)</p>
            <p className="m-0">Espejos de Agua</p>
            <p className="m-0">Calles</p>
            <p className="m-0">Puentes</p>
          </EtapaCard>
          <EtapaCard
            bgColorClass="bg-red-400"
            date="Jul 21 /  Oct 21"
            etapa="Segunda"
            loteo="96">
            <p className="m-0">Club House</p>
            <p className="">Areas Deportivas</p>
            <p className="m-0">Infraestructura (2° Parte)</p>
            <p className="m-0">Espejos de Agua</p>
            <p className="m-0">Calles</p>
            <p className="m-0">Puentes</p>
          </EtapaCard>
          <EtapaCard
            bgColorClass="bg-green-500"
            date="Nov 21 /  Mar 22"
            etapa="Tercera"
            loteo="112">
            <p className="m-0">Infraestructura (3° Parte)</p>
            <p className="m-0">Comercial</p>
            <p className="m-0">Educativo</p>
            <p className="m-0">Salud</p>
          </EtapaCard>
          <EtapaCard
            bgColorClass="bg-yellow-400"
            date="Mar 22 /  2023"
            etapa="Cuarta"
            loteo="120">
            <p className="m-0">Caballerizas</p>
            <p className="m-0">Picadero</p>
            <p className="m-0">Pista de Vareo y Salto</p>
          </EtapaCard>
        </div>
      </div>
    </div>
  </Layout>
);

export default LaHaciendaCountryPage;
