import React from 'react';

export default function EtapaCard({
  bgColorClass,
  date,
  etapa,
  loteo,
  children,
  className,
}) {
  return (
    <div
      className={
        'w-full lg:w-[300px] py-4 px-3 shadow-md rounded-md m-3 transition transform hover:shadow-lg ' +
        className
      }>
      {/* <div className="flex justify-end font-bold text-sm">{date}</div> */}
      <div className={'w-full h-2 my-2 ' + bgColorClass}></div>
      <h4 className="text-gray-500 my-4 font-normal">{etapa} Etapa</h4>
      <h3 className="font-bold">Loteo {loteo} U</h3>
      <div>{children}</div>
    </div>
  );
}
