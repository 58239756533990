import React from 'react';

export default function ReferenciaItem({ label, children, group }) {
  return (
    <div className="flex items-center">
      <div className="flex flex-col items-center px-2 text-xs font-bold border border-black rounded-full mr-4 my-1">
        {group && <p className="m-0 font-mono -mb-1">G</p>}
        <p className={`m-0 font-mono ${group ? '' : 'p-1'}`}>{label}</p>
      </div>
      <div className="font-bold leading-tight">{children}</div>
    </div>
  );
}
